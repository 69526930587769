import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import ContactForm from "../../components/contact-form";
import Layout from "../../components/layout";
import Seo from "../../components/seo"
import PortfolioFeed from "../../components/portfolio-feed";
import Quote from "../../components/quote";

const PortfolioHeader = () => (
  <section className="bg-gray-700 py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <h3 className="text-secondary-light">Client</h3>
              <p className="text-white">MK Election Services</p>
            </div>
            <div className="col-12 mt-5">
              <h3 className="text-secondary-light">Our Role</h3>
              <p className="text-white">Custom Software Development</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 mt-5 mt-lg-0">
          <h3 className="text-secondary-light">Summary</h3>
          <h2 className="text-white">
            729 SOLUTIONS CUSTOM-DEVELOPED A BALLOT VOTING AND SCANNING SOLUTION
            TO HANDLE A MASSIVE LOAD OF DATA IN A FULLY SECURE ENVIRONMENT.
          </h2>
        </div>
      </div>
    </div>
  </section>
);

const Visuals = () => (
  <section className="py-6 bg-light">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 p-0">
          <StaticImage
            style={{ width: "100%" }}
            src="../../images/portfolio/mke/visuals.png"
            alt="Mk elections Visuals"
          />
        </div>
      </div>
    </div>
  </section>
);

const ProjectGoals = () => (
  <section className="bg-portfolio-light py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Problem Statement</h2>
          <p>
            MKE needed 729 Solutions to create a custom solution for scanning
            and synchronizing a large volume of data with total accuracy and
            security.
          </p>
        </div>
        <div className="col-12 col-lg-6 mt-5 mt-lg-0">
          <h2>Project Goals</h2>
          <ol className="text-primary">
            <li>
              <span className="text-dark">
                Synchronize a huge amount of data from physical devices that are
                not always connected to the internet using an application that
                is accurate, secure and fast, but discreet for its use in a
                public setting.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Scan large numbers of various types of ballots quickly and
                accurately.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Show a high level of accuracy of data integrity during and after
                a vote in a high-security election environment.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Build a flexible system that can distinguish between multiple
                ballot types and work with any hard printing application.
              </span>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
);

const AwsPartner = () => (
  <section className="bg-light py-6">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6">
          <h2>
            729 IS PROUD TO BE PART OF THE AWS PARTNER NETWORK AND CONSULTING
            PARTNER.
          </h2>
        </div>
        <div className="col-12 col-lg-6">
          <div
            className="p-4"
            style={{
              border: "1px solid",
              borderRadius: "5px",
              width: "max-content",
            }}
          >
            <StaticImage
              imgStyle={{ width: "100%" }}
              src="../../images/partners/aws.png"
              alt="Aws Partner"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

const HowWeDidIt = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-2">Development Languages Used</h2>
              <p className="mb-0">C #</p>
            </div>
            <div className="col-12">
              <h2 className="mt-5">Tools Used</h2>
              <p className="mb-0">Aws</p>
            </div>
            <div className="col-12">
              <h2 className="mt-5">Frameworks Used</h2>
              <p className="mb-0">.net</p>
            </div>
            <div className="col-12">
              <h2 className="mt-5">Databases</h2>
              <p className="mb-0">Sql Server</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12 mb-5">
              <h2 className="mb-2 mt-5 mt-lg-0">Successes</h2>
              <p>
                729 Solutions designed and built an on site application in .Net
                that could run completely stand alone on laptops. In addition,
                we built a synchronization tool to manage large volumes of data
                from the physical scanning devices that were not always
                connected to the internet. When an internet connection becomes
                available, all data is backed up, utilizing cloud computing to
                its fullest potential to ensure the results were delivered
                quickly and securely.
              </p>
              <p>
                The application successfully delivered on the intense data
                integrity requirements of this customer.
              </p>
              <p>
                In addition, AWS was used to provide high performance image
                manipulation in conjunction with high integrity Optical Mark
                Recognition software that 729 customized to the client’s needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const ProjectForm = ({location}) => (
  <section className="bg-light py-6 mb-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Have a Project?</h2>
          <p className="pr-5">We'd love to hear about it!</p>
          <small className="text-muted pr-5">
            Email us at{" "}
            <a href="mailto:solicitations@729solutions.com">
              solicitations@729solutions.com
            </a>{" "}
            for solicitations regarding partnerships, linkbacks, or
            subcontracting opportunities.
          </small>
        </div>
        <div className="col-12 col-lg-6">
          <ContactForm showHowYouFindUs={false} buttonText="Submit" location={location} />
        </div>
      </div>
    </div>
  </section>
);

const MkeQuote = () => (
  <section className="my-6 bg-dark">
    <div className="container">
      <div className="row align-items-center p-5">
        <div className="col-12 col-lg-6">
          <Quote
            dark
            quote="We found one of 729’s most impressive abilities was to build us something that worked in a very small number of months while we were only paying half attention!"
            name="Caleb Kleppner"
            company="MKE"
          />
        </div>
        <div className="col-12 col-lg-6">
          <div className="p-6">
            <div className="p-2 bg-light">
              <StaticImage
                style={{ width: "100%" }}
                src="../../images/portfolio/mke/logo.png"
                alt="MK Election Services"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="MKE" 
      />
      <PortfolioHeader />
      <Visuals />
      <ProjectGoals />
      <AwsPartner />
      <HowWeDidIt />
      <MkeQuote />
      <ProjectForm location={location} />
      <PortfolioFeed />
    </Layout>
  );
};

export default Index;
